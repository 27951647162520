import React, { useState, useEffect, useRef } from 'react';
import Header from '../src/components/common/Header';
import Footer from '../src/components/common/Footer';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const [showNavbar, setShowNavbar] = useState(true);
  const lastScrollPositionRef = useRef(0);

  const toggleIsMore = () => {
    const OFFSET = 57.5;
    if (window.pageYOffset < 0) {
      return;
    }
    if (Math.abs(window.pageYOffset - lastScrollPositionRef.current) < OFFSET) {
      return;
    }
    let show = window.pageYOffset < lastScrollPositionRef.current;
    setShowNavbar(show);
    lastScrollPositionRef.current = window.pageYOffset;
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleIsMore);
    return () => {
      window.removeEventListener('scroll', toggleIsMore);
    };
  }, []);
  return (
    <div id="App">
      <Header showNavbar={showNavbar} />
      <div className={`main-wrapper ${!showNavbar ? 'navShow' : ''}`}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Layout;