import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

import LOGO_IMG from '../../assets/Logo/logo.png';
import FACEBOOK from '../../assets/Icon/facebook.svg';
import YOUTUBE from '../../assets/Icon/youtube.svg';
import MEDIUM from '../../assets/Icon/medium.svg';
import XANGLE from '../../assets/Icon/xangle.svg';

const navList = [
  {
    name: "HOME",
    to: "/"
  },
  // {
  //   name: "EVENT",
  //   to: "/event/soli-hvh"
  // },
]

const snsList = [
  {
    link: 'https://www.facebook.com/solitairecryptowar/',
    name: 'facebook',
    img: FACEBOOK,
  },
  {
    link: 'https://twitter.com/SolitaireCW',
    name: 'xangle',
    img: XANGLE,
  },
  {
    link: 'https://medium.com/@solitairecryptowar',
    name: 'medium',
    img: MEDIUM,
  },
  {
    link: 'https://www.youtube.com/@SolitaireCryptoWar',
    name: 'youtube',
    img: YOUTUBE,
  },
]

export default function Header({showNavbar}) {
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth <= 768);
  const [isMobileShow, setMobileIsShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!isWideScreen && isMobileShow) {
      closeMobileMenu()
    }
  }, [isWideScreen]);


  const openMobileMenu = () => {
    setMobileIsShow(true)
    document.querySelector('html').style.overflow = 'hidden';
  }
  const closeMobileMenu = () => {
    setMobileIsShow(false)
    document.querySelector('html').style.overflow = 'auto';
  }

  const moveLink = (type, link) => {
    if (type === 'nav') {
      navigate(link)
    } else if (type === 'link') {
      window.open(link)
    }
    closeMobileMenu()
  }


  return (
    <>
      <header className={showNavbar ? 'show' : ''}>
        <div className="navigation">
          <div className="navigation__inner">
            <img onClick={() => navigate("/")} className="logo" src={LOGO_IMG} alt="logo img" />
            {/* {isWideScreen ? <button className="m-gnb" onClick={() => navigate("/event/soli-hvh")}>EVENT</button> : null} */}
            {!isWideScreen &&
            <ul className="gnb">
              {navList.map((item, index) => {
                return <li onClick={() => navigate(item.to)} key={index}>{item.name}</li>
              })}
            </ul>}
          </div>
          {!isWideScreen ?
          <div className="sns-wrap">
            {snsList.map((item, index) => {
              return <img onClick={()=> window.open(item.link)} key={index} src={item.img} alt={`${item.name} icon`}></img>
            })}
          </div> :
          <button className="m-meun-btn" onClick={openMobileMenu}></button>}
        </div>
      </header>
      {isWideScreen &&
        <div className={`m-nav-wrapper ${isMobileShow ? 'open' : 'close'}`}>
          <div className="m-nav-inner">
            <div className="m-nav-head">
              <button onClick={closeMobileMenu} className="m-menu-close"></button>
            </div>
            <div className="m-nav-body">
              <ul className="gnb">
                {navList.map((item, index) => {
                  return <li onClick={() => moveLink('nav', item.to)} key={index}>{item.name}</li>
                })}
              </ul>
              <div className="foot-container">
                <div className="sns-wrap">
                  {snsList.map((item, index) => {
                    return <img onClick={()=> moveLink('link', item.link)} key={index} src={item.img} alt={`${item.name} icon`}></img>
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  )
}