import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import PhoneImg from '../../assets/Content/phone_img.png';
import { Autoplay } from 'swiper/modules';

export default function SetSwiper({slideImgList}) {
  return (
    <div className="phone-swiper-inner">
    <img className="phones" src={PhoneImg} alt="Phone game img" />
      <Swiper
        className="slider-component"
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
      >
      {slideImgList.map((item, index) => {
        let imageUrl = require(`../../assets/Content/Screenshot_0${item}.png`)
        let slideStyle = {
          backgroundImage: `url(${imageUrl})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat'
        };

        return (
          <SwiperSlide style={slideStyle} className="img-item" key={index}>
          </SwiperSlide>
        );
      })}
      </Swiper>
    </div>
  );
};


// onSlideChange={() => console.log('slide change')}
// onSwiper={(swiper) => console.log(swiper)}