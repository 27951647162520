import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { Link } from "react-router-dom";
import 'swiper/css';

function TempImage() {
  return (
    <div className="tempImage-wrapper">
      <div className="tempImage"></div>
    </div>
  )
}


export default function SetSwiper(props) {
  const { groupInfo, items } = props;
  const itemLength = items.length < 3 ? items.length : 3;
  return (
    <>{items.length ?
      <div className="set-swiper-wrapper">
        <Swiper
          className="slider-component"
          modules={[Navigation]}
          observer={true}
          observeParents={true}
          navigation={{
            nextEl: `#btn-next-${groupInfo?.id}`,
            prevEl: `#btn-prev-${groupInfo?.id}`,
          }}
          breakpoints={{
            "@0.25": {
              direction: 'horizontal',
              loop: false,
              slidesPerView: 1.2,
              centeredSlides: true,
              roundLengths: true,
              slidesPerGroup: 1,
              spaceBetween: 16
            },
            "@0.50": {
              direction: 'horizontal',
              loop: false,
              slidesPerView: 1.5,
              centeredSlides: true,
              roundLengths: true,
              slidesPerGroup: 1,
              spaceBetween: 16
            },
            "@1.00": {
              slidesPerView: 3,
              slidesPerGroup: 3,
              // slidesPerView: 3,
              // slidesPerGroup: 3,
              spaceBetween: 24
            },
            "@1.50": {
              slidesPerView: itemLength,
              slidesPerGroup: itemLength,
              // slidesPerView: 4,
              // slidesPerGroup: 4,
              spaceBetween: 24
            }
          }}
        >
          <div className="slider-component__header">
            <div className="slider-title">
              <div className="title-info">
                <p>{groupInfo.title}</p>
              </div>
            </div>
          </div>
          {items.map((item, index) => (
            <SwiperSlide className="card-item" key={index}>
              <a href={item.link} target="_blank" rel="noreferrer noopener">
                {item.image ? <img src={item.image} alt={`${item.title} thumbnail`} /> : <TempImage />}
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        <button id={`btn-prev-${groupInfo?.id}`} className={`btn-prev`}></button>
        <button id={`btn-next-${groupInfo?.id}`} className={`btn-next`}></button>
      </div> : null}
    </>
  );
};


// onSlideChange={() => console.log('slide change')}
// onSwiper={(swiper) => console.log(swiper)}