import Privacypolicy from './pages/policy/Privacypolicy';
import Termsofservice from './pages/policy/Termsofservice';
import { Routes, Route } from 'react-router-dom';
import Layout from "./Layout";
import Main from '../src/pages/Main';
// import Event from '../src/pages/Event';

function App() {
  return(
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Main />} />
        {/* <Route path="/event/:type" element={<Event />} /> */}
      </Route>
      <Route path="/policy/privacypolicy" element={<Privacypolicy />} />
      <Route path="/policy/termsofservice" element={<Termsofservice />} />
    </Routes>
  )
}
export default App;
