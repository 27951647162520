import { useState, useEffect } from 'react';
import GooglePlayDownload from '../assets/Icon/googleplay.png';
// import AppStoreDownload from '../assets/Icon/appstore.png';
import APKDownload from '../assets/Icon/apk.png';
import PhoneImg from '../assets/Content/phone_img.png';
import NPCImg from '../assets/Content/npc_img.png';
import CardImg from '../assets/Content/card_img.png';
import GameImg from '../assets/Content/game_img.png';
import UNION from '../assets/Icon/Union.png';
import SetSwiper from '../components/slider/SetSwiper';
import PhoneSwiper from '../components/slider/PhoneSwiper';
// import StatusSnackbar from "../components/StatusSnackbar"


function Main() {
  // const [open, setOpen] = useState(false);
  const [news, setNews] = useState({
    groupInfo: {
      title: 'NEWS',
      id: 'news'
    },
    items: []
  })
  const symbol = ['ETH', 'USDT', 'UNI', 'USDC', 'LINK', 'MATIC', 'MEV', 'MPL'];
  const tutorials = {
    groupInfo: {
      title: 'TUTORIALS',
      id: 'tutorials'
    },
    items: [
      {
          "title": "[FAQ]",
          "date": "2023-11-17 04:55:56",
          "link": "https://medium.com/@solitairecryptowar/faq-dc767f0d29ec?source=rss-a318341a4b37------2",
          "image": "https://cdn-images-1.medium.com/max/1024/1*HzDgqkvrdKugKE5i_bQT-g.png"
      },
      {
          "title": "[How to earn]",
          "date": "2023-11-01 06:16:24",
          "link": "https://medium.com/@solitairecryptowar/how-to-earn-f103afc8278a?source=rss-a318341a4b37------2",
          "image": "https://cdn-images-1.medium.com/max/1024/1*8DhBP640R1DaVejSFPBsTw.png"
      },
      {
          "title": "[How to start]",
          "date": "2023-11-01 05:58:17",
          "link": "https://medium.com/@solitairecryptowar/how-to-start-868cf0673b39?source=rss-a318341a4b37------2",
          "image": "https://cdn-images-1.medium.com/max/1024/1*v6snh9hB_ESl84gyFk7OIA.png"
      },
    ]
  }


  const getNewsList = async () => {
    let datas = [];

    const name = "solitairecryptowar";
    const RSSUrl = `https://medium.com/feed/@${name}`;
    const RSSConverter = `https://api.rss2json.com/v1/api.json?rss_url=${RSSUrl}`;

    const response = await fetch(RSSConverter);
    const data = await response.json();

    if (!data?.items?.length) return;

    for(let item of data.items) {
      let image = item.thumbnail;
      if (!/\.(jpg|jpeg|png)$/i.test(image)) {
        image = null;
      }
      datas.push({
        "title": item.title,
        "link": item.link,
        "image": image,
        "date": item.pubDate })
    }

    setNews({
      groupInfo: {
        title: 'NEWS',
        id: 'news'
      },
      items: datas
    })
  }

  // const openComingSoon = () => {
  //   setOpen(true)
  // };

  // const closeComingSoon = () => {
  //   setOpen(false)
  // };

  useEffect(() => {
    getNewsList()
  }, []);
  const slideImgList = [ '1', '2', '3', '4' ]
  return (
    <div className="main">
      <div className="main-banner"></div>
      <section className="section01">
        <div className="section01__inner">
          <div className="phone-wrapper">
            <img className="npc" src={NPCImg} alt="NPC img" />
            <div className="phone-swiper-wrapper">
              <img className="phone" src={PhoneImg} alt="Phone game img" />
              <PhoneSwiper slideImgList={slideImgList} />
            </div>
          </div>
          <div className="content-info">
            <div className="content-info__desc">
              <p className="content-info__desc--title">LEAD YOUR CRYPTO<br />
              TO VICTORY!</p>
              <ul>
                <li>Solitaire Crypto War is a PVP puzzle card game where players compete with each other to achieve the best time records in a classic solitaire klondike.</li>
                <li>Solitaire Crypto War is free to play and allows participants to compete against other players through the entry fee using various cryptocurrencies such as ETH, USDT, USDC, MATIC, UNI, LINK, MEV and MPL more, together with the chance to win prizes.</li>
              </ul>
            </div>
            <div className="content-info__btnwrap">
              <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.memoriki.solig"><img src={GooglePlayDownload} alt="GooglePlay Download img" /></a>
              {/* <button onClick={openComingSoon}><img src={AppStoreDownload} alt="AppStore Download img" /></button> */}
              <a href="https://soli-s3.s3.amazonaws.com/public/solitairecryptowar240124.apk"><img src={APKDownload} alt="APK Download img" /></a>
            </div>
          </div>
        </div>
      </section>
      <section className="section02">
        <div className="section02__inner">
          <div className="content-info">
            <div className="content-info__desc">
              <p className="content-info__desc--title">Competition<br />
              With Skill</p>
              <span className="content-info__desc--sub">Play solitaire against players from around the world. All games are created equally!<br className="break" /> Everybody gets the same set of cards when competing. Use your skills to beat your opponents and take home the prize pool!</span>
            </div>
          </div>
          <img src={CardImg} alt="card game img" />
        </div>
      </section>
      <section className="section03">
        <div className="section03__inner">
          <div className="content-info">
            <div className="content-info__desc">
              <p className="content-info__desc--title">WHAT IS SOLICOIN?</p>
              <span className="content-info__desc--sub">SoliCoin is an in-game item for Solitaire Crypto War. It can be exchanged with each crypto.<br />
              Solicoin is used for the entry fee for various pool, and Players can get bonus solicoin after the game.</span>
              <p className="content-info__desc--primary">*Initial exchange ratio is 1:1</p>
            </div>
          </div>
          <div className="ingame-wrapper">
            <div className="ingame-box">
              <div className="ingame-border">
                <div className="ingame-box-inner">
                  <ul>
                    {symbol.map((item, index) => {
                      return <li key={index}>
                        <div className="symbol-wrapper">
                          <img src={require(`../assets/Symbol/${item}.svg`)} alt={`${item} symbol`} />
                        </div>
                        <p>{item}</p>
                      </li>
                    })}
                  </ul>
              </div>
            </div>
            </div>
            <div className="ingame-info">
              <img src={UNION} alt="UNION icon" />
              <p>INGAME</p>
            </div>
            <div className="ingame-box">
              <div className="ingame-border">
                <div className="ingame-box-inner ingame">
                  <ul>
                    {symbol.map((item, index) => {
                      return <li key={index}>
                        <div className="symbol-wrapper">
                          <img src={require(`../assets/Symbol/${item}.svg`)} alt={`${item} symbol`} />
                        </div>
                        <p>SOLI-<span>{item}</span></p>
                      </li>
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        <div>
        </div>
        </div>
      </section>
      <section className="section04">
        <div className="section04__inner">
          <div className="content-info">
            <div className="content-info__desc">
              <p className="content-info__desc--title">WIN CRYPTO PRIZES</p>
              <span className="content-info__desc--sub">The rules for prizes pool are different for each type of pool.<br className="break" />
              Players can Use Ticket to win big!</span>
            </div>
          </div>
          <img src={GameImg} alt="game img" />
        </div>
      </section>
      <section className="section05">
        <div className="section05__inner">
        <SetSwiper {...tutorials} type="view" />
        <SetSwiper {...news} type="view" />
        </div>
      </section>
      {/* <StatusSnackbar open={open} handleClose={closeComingSoon} /> */}
    </div>
  )
}

export default Main;
